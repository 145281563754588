<!-- eslint-disable vue/no-multiple-template-root -->
<template>
  <div v-if="!!landingPage">
    <CmsHero
      :title1="landingPage.hero_title_line1"
      :title2="landingPage.hero_title_line2"
      :claims="landingPage.hero_claims"
      :hero-hint-label="landingPage.hero_hint_label"
      :hero-hint-text="landingPage.hero_hint_text"
      :hero-hint-mobile-text="landingPage.hero_hint_mobile_text"
      :bg="landingPage.hero_background"
      :mobile-background="landingPage.hero_mobile_background" />

    <ContentWrapper v-if="landingPage" class="bg__gradient_bottom px-md-0">
      <v-container>
        <CmsCardsWrapper :cards-payload="landingPage.card_module ?? []" />
      </v-container>
    </ContentWrapper>

    <CmsContent
      v-for="content in landingPage.contents"
      :id="content.content_id"
      :key="content.content_id"
      :content-id="content.content_id" />

    <v-divider />

    <OrderNow :order-now="landingPage.order_now_content" />
  </div>

  <GlobalSidebar :title="t('cart')" :persistent="true">
    <SidebarCartSummary v-if="currentGlobalSidebarType === ACTIVE_SIDEBAR_TYPE.CART" />
  </GlobalSidebar>
</template>

<script setup lang="ts">
import { ACTIVE_SIDEBAR_TYPE, DirectusModule, LandingPageFieldsFilter } from '~/types/enums'
import type { LandingPage } from '~/types/types'

const { $directus, $readItems } = useNuxtApp()
const sidebarStore = useSidebarStore()
const cartStore = useCartStore()

const paidCart = useCookie('paidCart')
paidCart.value = undefined
cartStore.getCart()
const { currentGlobalSidebarType } = storeToRefs(sidebarStore)
const { t } = useI18n()
const { data } = await useAsyncData(DirectusModule.LandingPage, () => {
  return $directus.request(
    $readItems(DirectusModule.LandingPage as any, {
      fields: LandingPageFieldsFilter,
    }),
  )
})

const landingPage = data.value as unknown as LandingPage
if (!landingPage)
  throw createError({
    status: 404,
    statusText: 'Page Not Found',
  })

useHead({
  link: [
    {
      rel: 'canonical',
      href: 'https://containerdienst.de',
    },
  ],
})

if (landingPage?.meta_title && landingPage?.meta_description) {
  useSeoMeta({
    title: landingPage.meta_title || '',
    description: landingPage.meta_description || '',
  })
}
const CdeLogo = `
         ███████████
     ██████████████████
   ██████████████████████
 ████████          ███████                             ███                                  ███ ███                                              █
 ██████                                ███       ███                     ██                 ███         ██                 ██    ███         ██  █    ███
██████    ███████ ████████  █████████ ███████ ███   ██ ███ █████████  ███  ███ ████████████████ ███  ███  ███ █████████ ██   ██ ███████   ██    ██  ██   █
█████    ███      ██     ██ ███    ██  ███      ██████ ███ ███    ██ ███ █████ ███    ██    ███  ██ █████████ ███    ██  ███     ███      █      █ ██ ███ █
█████    ███      ██    ███ ███   ███  ███   ███   ███ ███ ███    ██ ███       ███    ██    ███  ██ ███       ███    ██      ███ ███      █      █ ██
██████    ███████  ███████  ███   ███  ███████████████ ███ ███   ███  ████████ ███    █████████ ███  ████████ ███   ███ ████████ ██████ █ ██    ██  ██
 ██████                                                                                                                                 █   ███       ████
  ███████          ███████
   █████████████████████
     █████████████████
         ██████████
`

console.log(CdeLogo)
</script>
